import styled from "styled-components";

const FooterContainer = styled.footer`
min-height: 80px;
  background-color: #eee;
  padding: 20px 10px;

  p {
    font-size: 13px;
    text-align: center;
  }
`;

const Foot = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #adb5bd;
  max-width: 1440px;
  margin: auto;
  position: relative;
  padding-bottom: 102px;
`

const Footer = () => {
  return (
    <FooterContainer>
      <p>Copyright © 2024 Amarres con el maestro Horacio | Todos los derechos Reservados.</p>
    </FooterContainer>
  );
};

export default Footer;
import styled from "styled-components";

const Section = styled.section`
  position: relative;
  background-color: #000;
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: center;

  border-style: double;
  border-width: 10px 10px 10px 10px;
  border-color: grey;
  box-shadow: 0px 0px 10px 0px #000000;
`;

const Lists = styled.div`
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-family: "Sansita", Sans-serif;
    font-weight: 600;
    max-width: 1140px;
    width: 100%;

    p {
        font-size: 20px;
        text-align: center;
        border-radius: 5px;
        padding: 5px;
        text-decoration: underline;
    }
`

const Left = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 45%;
    max-width: 45%;

    p:hover {
        background-color: grey;
        cursor: pointer;
        
    }
`

const Right = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 45%;
    max-width: 45%;

    p:hover {
        background-color: grey;
        cursor: pointer;
    }
`

const List = () => {
    return (
        <Section id="list">
            <Lists>
                <Left>
                    <p>-Amarres de Amor</p>
                    <p>-Amarres sexuales</p>
                    <p>-Endulzamiento</p>
                    <p>-Alejamientos</p>
                    <p>-Tumbar hechizos magia negra.</p>
                    <p>-Ceremonias para mejorar economia.</p>
                </Left>
                <Right>
                    <p>-Mal de Ojo</p>
                    <p>-Limpias espirituales</p>
                    <p>-Limpias de aura y lugares.</p>
                    <p>-Rituales Sexuales.</p>
                    <p>-Expulsion de todo mal interno.</p>
                    <p>-Regresar felicidad.</p>
                </Right>
            </Lists>
        </Section>
    );
};

export default List;
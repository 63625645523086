import Layout from "./components/_shared/Layout";
import Banner from "./components/Banner";
import Services from "./components/Services";
import List from "./components/List";
import About from "./components/About";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';


const App = () => {
  return (
    <Layout>
      <Banner />
      <Services />
      <List />
      <About />
      {/* <Testimonials /> */}
      <FloatingWhatsApp buttonStyle={{"bottom": "20px", "right": "20px"}} />
      <TawkMessengerReact
        propertyId="66527b22981b6c564774b9e4"
        widgetId="1hup320bn" />
    </Layout>
  );
}

export default App;

import styled from "styled-components";
import bg from "../../src/images/banner.jpg";

const Section = styled.section`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("https://amarremagiablanca.com/wp-content/uploads/2023/06/IMG_3907_jpg-1-768x576.jpg");
  background-position: center center;
  padding: 10px;

  .shadow {
    background-color: rgba(0, 0, 0, 0.48);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }
`;

const TextContainer = styled.div`
  position: relative;
  z-index: 100;
  max-width: 1144px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  h1 {
    color: #fff;
    font-family: "Sansita Swashed", Sans-serif;
    font-size: 60px;
    font-weight: 600;
    line-height: 75px;
  }

  h2 {
    color: #fff;
    font-family: "Sansita", Sans-serif;
    font-size: 30px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 68px;
  }

  .disclosure {
    text-align: center;
    color: #FFFFFF;
    font-family: "Roboto", Sans-serif;
    font-size: 9px;
    font-weight: 400;
    margin-bottom: 34px;
    line-height: 20px;
  }
`

const Number = styled.a`
position: relative;
z-index: 100;
  display: inline-block;
  margin-bottom: 20px;

  img {
    border-radius: 10px;
    max-width: 100%;
    height: auto;
  }
`

const CardsContainer = styled.div`
  position: relative;
  z-index: 100;
  display: flex;

  .card {
    border-style: solid;
    border-color: #FFFFFF;
    padding: 10px 10px 32px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    font-family: "Sansita Swashed";
  }

  @media(max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
`

const Banner = () => {
  return (
    <Section id="home">
      <div className="shadow"></div>
      <TextContainer>
        <h1>
          Amarres con el Maestro Horacio
        </h1>
        <h2>¿Quieres Volver con tu Expareja y no sabes como? ¿Estás cansado de sufrir por el amor? ¿Hay una tercera persona interpuesta en tu camino? Amarres de amor efectivos – Amarres sexuales – Uniones Espirituales – Hechizos para que regrese – Endulzamientos – Atraer pareja – Reconquistar pareja – Alejamientos</h2>
        <Number href="tel:+12132581358"><img decoding="async" src="https://amarremagiablanca.com/wp-content/uploads/2023/06/Maestro-Gael.gif" class="attachment-large size-large wp-image-357" alt="" /></Number>
        <div className="disclosure">El Costo de llamada es según el plan de su operador de telefonía <br />
          (Valor de $ 0.09 USD a $ 0.99 USD)</div>
      </TextContainer>
      <CardsContainer>
        <div className="card">
          Amarres de amor.<br />
          Hechizos de Amor.<br />
          Amarres del mismo sexo.<br />
          Hechizos a larga y corta distancia.<br />
          Casamientos espirituales.<br />
          Dominio total de la pareja.
        </div>
        <div className="card">
          Retornos.<br />
          Tumbar trabajos.<br />
          Revelacion de rostros.<br />
          Ayudas para la Suerte.<br />
          Prosperidad y buenas rachas.<br />
          Limpieza de aura y cuerpo.<br />
          Retiro de daños.<br />
        </div>
        <div className="card">
          Proteccion contra la envidia.<br />
          Magia de Vudú Africano.<br />
          Magia suprema.<br />
          Ciencias Ocultas.<br />
          Magia negra, blanca, roja y gris.<br />
        </div>
      </CardsContainer>
      <Number href="tel:+12132581358"><img decoding="async" src="https://amarremagiablanca.com/wp-content/uploads/2023/06/Maestro-Gael.gif" class="attachment-large size-large wp-image-357" alt="" /></Number>
    </Section>
  );
};

export default Banner;
import styled from "styled-components";
import flag from "../../images/flag-0.png";

const HeaderContainer = styled.div`
  width: 100%;
  max-width: 100vw;
  z-index: 100;
  display: flex;
  justify-content: center;
  background-color: #fff;
`;

const MenuContainer = styled.div`
  width: 100%;
  height: 58px;
  max-width: 1240px;
  padding: 0 20px;
  display: flex;
  align-items: center;

  .image {
    display: flex;
    align-items: center;
    color: #222;
    font-size: 18px;
    font-weight: 600;

    img {
      width: 36px;
      height: 36px;
      margin-right: 12px;
    }
  }
`;


const Header = () => {
  return (
    <HeaderContainer>
          <MenuContainer>
              <a className="image" href="/#home">
                <img src={flag} alt="" />
                Amarres con el Maestro Horacio
              </a>
          </MenuContainer>
    </HeaderContainer>
  );
};

export default Header
import styled from "styled-components";
import love0 from "../images/amarres-de-amor-hechizo.jpg";
import love1 from "../images/amarres-de-amor.jpg";
import altar from "../images/altar-mayor-sorte.jpg";
import love2 from "../images/amor.jpg";

const Section = styled.section`
  position: relative;
  padding: 10px;
  max-width: 1140px;
  width: 100%;

  

  @media(min-width: 768px) {
    .articles {
      display: flex;
      justify-content: space-between;
    }
  
    .art {
      flex-basis: 48%;
      max-width: 48%;
      display: flex;
      flex-direction: column;
    }
  }
`;

const Article = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 48px;

  h2 {
    font-size: 40px;
  }

  h3 {
    font-size: 30px;
  }

  .img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
    flex-basis: 100%;
    max-width: 100%;
    margin-right: 0px;
  }

  .img-container {
    margin-bottom: 20px;
  }

  .text {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    max-width: 100%;
  }

  p {
    font-size: 16px;
    line-height: 25px;
    text-align: justify;
  }

  @media(min-width: 768px) {
    flex-direction: row;

    .img {
      flex-basis: 33%;
      max-width: 33%;
      margin-right: 24px;
    }

    .img-container {
      margin-bottom: 0;
      flex-basis: 33%;
        max-width: 33%;
        margin-right: 24px;
  
        img {
          max-width: 100%;
      height: auto;
        }
    }

    .text {
      flex-basis: 67%;
      max-width: 67%;
    }
  }
`

const Number = styled.a`
  position: relative;
  z-index: 100;
  display: inline-block;
  margin: auto;

  img {
    border-radius: 10px;
    max-width: 100%;
    height: auto;
  }
`

const Services = () => {
  return (
    <Section id="services">
      <Article>
        <img className="img" src={love0} alt="" />
        <div className="text">
          <h2>Amarres de Amor</h2>
          <p>Muchas parejas son separadas por personas con envidia de ver su felicidad, o en algunos casos por sus propias ex parejas las cuales les enfurece ver su nueva unión y amor, personas que no les importa destruir una relacion, un hogar o hasta una familia entera.
          </p>
          <p>Nuestros maestros ponen a tu disposición toda su fuerza espiritual, su conocimiento y larga experiencia para ayudarte a tumbar y a deshacer todo mal, brujería, hechicería y maldicion que pueda perjudicar tu entorno amoroso y tu tranquilidad</p>
          <Number href="tel:+12132581358"><img decoding="async" src="https://amarremagiablanca.com/wp-content/uploads/2023/06/Maestro-Gael.gif" class="attachment-large size-large wp-image-357" alt="" /></Number>
        </div>
      </Article>
      <Article>
        <img className="img" src={love1} alt="" />
        <div className="text">
          <h2>Amarres Sexuales</h2>
          <h3>Magia Roja</h3>
          <p>Has hecho todo lo posible para encontrar el amor verdadero, pero sigues sin suerte? No te decepciones, ni te desanimes, pues la vida siempre nos da una segunda oportunidad cuando se trata de asuntos del corazón.
          </p>
          <p>Tenemos la solución y el antidoto que ha servido a muchas personas a lo largo de la historia.
          </p>
          <p>Nuestros maestros con mas de 35.000 casos de parejas regresadas se pondran en contacto contigo y te ayudaran a resolver esta situacion por medio de la ayuda de los espiritus.</p>
          <Number href="tel:+12132581358"><img decoding="async" src="https://amarremagiablanca.com/wp-content/uploads/2023/06/Maestro-Gael.gif" class="attachment-large size-large wp-image-357" alt="" /></Number>
        </div>
      </Article>
      <Article>
        <img className="img" src={altar} alt="" />
        <div className="text">
          <h2>Une tu Espíritu</h2>
          <p>Nuestros guías espirituales chamanicos, por medio de sus conocimientos heredado de generación en generación, practican las técnicas del espiritismo, tienen el don de la sanacion enseñado por sus antepasados, técnicas que te ayudaran a recuperar el amor, la unión la estabilidad, la confianza, el amor verdadero en pareja, el extasis en las relaciones sexuales y así allar tu plena felicidad.</p>
          <p>Realizamos trabajos por todo el mundo ayudando a las personas a encontrar la estabilidad espiritual, económica y amorosa.</p>
          <Number href="tel:+12132581358"><img decoding="async" src="https://amarremagiablanca.com/wp-content/uploads/2023/06/Maestro-Gael.gif" class="attachment-large size-large wp-image-357" alt="" /></Number>
        </div>
      </Article>
      <Article>
        <div className="img-container">
          <img  src={love2} alt="" />
        </div>
        <div className="text">
          <h2>Recuperar al Ser Amado</h2>
          <p>Somos maestros 100% profesionales y poderosos en las artes esotericas,No hay nada que no podamos resolver con la ayuda de los espiritus, realizamos poderosos amarres de amor, alejamientos de amantes, rituales para mejorar la sexualidad y mas, Comentale tu caso a nuestro maestro y solicita tu primera valoracion gratis via WhatsApp. </p>
          <Number href="tel:+12132581358"><img decoding="async" src="https://amarremagiablanca.com/wp-content/uploads/2023/06/Maestro-Gael.gif" class="attachment-large size-large wp-image-357" alt="" /></Number>
        </div>
      </Article>
      <div className="articles">
        <Article className="art">
          <h3>Alejamientos y Bloqueos</h3>
          <p>Eliminamos y bloqueamos todo tipo de Auras negativas, Energías Malignas, Trabajos de Brujería que no te dejan Ser, Alejamos a terceras personas que se están interponiendo en tu realización y en tu felicidad, no permitas que destruyan tu hogar, tu vida y tu felicidad ¡Consúltanos Ya! </p>
        </Article>
        <Article className="art">
          <h3>Limpiezas</h3>
          <p>Los cristales son minerales que han sido utilizados durante milenios para curar, proteger, limpiar la energía y dar un equilibrio a la persona que los usa. Si son usados correctamente, te pueden ayudar a mejorar tu vida en muchos aspectos, como salud, dinero y amor.</p>
        </Article>
      </div>
    </Section>
  );
};

export default Services;
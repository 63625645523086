import styled from "styled-components";

const Section = styled.section`
width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #000;
  padding: 50px 10px;

  border-style: double;
  border-width: 10px 10px 10px 10px;
  border-color: grey;
  box-shadow: 0px 0px 10px 0px #000000;

  .container {
    max-width: 1140px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
   

  &:hover {
    cursor: pointer;
    background-color: grey;
    border-color: #000;
  }

  p {
    font-size: 25px;
    color: #fff;
    text-align: center;
    margin-bottom: 48px;
  }

  a {
    font-size: 10px;
    color: #6EC1E4;
    text-align: center;
    text-decoration: underline;
  }
`;

const Number = styled.a`
  position: relative;
  z-index: 100;
  display: inline-block;
  margin-bottom: 20px;

  img {
    border-radius: 10px;
    max-width: 100%;
    height: auto;
  }
`

const List = () => {
  return (
    <Section id="list">
      <div className="container">
        <p>Somos brujos especializados en amarres de amor trabajamos con  Magia Blanca y roja las mas poderosas para este tipo de casos con resultados en menos de 8 días, contamos con mas de 45 años de experiencia y miles de personas con resultados positivos donde encuentran el amor perdido o recuperan a su pareja que se había ido por otras personas, es momento de comenzar tu amarre de amor con brujos mayores que te hablaran en español.</p>
        <Number href="tel:+12132581358"><img decoding="async" src="https://amarremagiablanca.com/wp-content/uploads/2023/06/Maestro-Gael.gif" class="attachment-large size-large wp-image-357" alt="" /></Number>
        <a href="">Política de Privacidad – Términos y condiciones</a>
      </div>
    </Section>
  );
};

export default List;